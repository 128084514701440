import {
    FormControl,
    FormLabel,
    Select,
    Stack,
    Td,
    Text,
    Th,
    Tr,
    VStack,
    Button
} from '@chakra-ui/react'
import React, {useEffect, useState, useCallback} from 'react'
import {Container, ResultsTable, ContentButton} from 'starterUiComponents'
import range from 'lodash/range'
import ApiFetch from '../../utils/api'
import {useLocation} from '@reach/router'

const TransmissionPage = () => {
    const [selectors, setSelectors] = useState({
        years: [],
        makes: [],
        models: [],
        engines: [],
        transmissions: [],
    })

    const [selected, setSelected] = useState({
        year: '',
        make: '',
        model: '',
        engine: '',
    })

    const [fetching, setFetching] = useState(false)
    const {year, make, model, engine} = selected
    const {years, makes, models, engines, transmissions} = selectors

    const noResults =
        year === undefined
            ? 'Please select the fields above to search for results.'
            : 'Please continue entering all the vehicle information above.'

    const some_engine_selected = (typeof engine !== 'undefined' && engine !== '')

    /* Get the "selectors" information */

    const fetchSelectors = React.useCallback(
        async (year, make, model, engine) => {
            let url = `/transmissions/selectors?year=${year}`
            make && (url += `&make=${make}`)
            model && (url += `&model=${model}`)
            engine && (url += `&engine=${engine}`)
            try {
                setFetching(true)
                const result = await ApiFetch({
                    method: 'GET',
                    path: url,
                })
                setSelectors((selectors) => ({
                    ...selectors,
                    makes: result?.makes ?? [],
                    models: result?.models ?? [],
                    engines: result?.engines ?? [],
                    transmissions: result?.transmissions ?? [],
                }))
            } catch (error) {
                console.log('error: ', error)
            } finally {
                setFetching(false)
            }
        },
        []
    )

    const getYearsSelectors = useCallback((oldestYear) => {
        const currentYear = new Date().getFullYear()
        return range(oldestYear, currentYear + 1, 1).reverse()
    }, [])

    useEffect(() => {
        if (year) {
            fetchSelectors(year, make, model, engine)
        }
    }, [year, make, model, engine, fetchSelectors])

    const fetchYear = React.useCallback(async () => {
        ApiFetch({
            method: 'GET',
            path: '/transmissions/lowest-year',
        })
            .then((res) => {
                setSelectors((selectors) => ({
                    ...selectors,
                    years: getYearsSelectors(res.lowestYear),
                }))
            })
            .catch((err) => {
                // console.log('err: ', err)
            })
    }, [getYearsSelectors])

    useEffect(() => {
        fetchYear()
        const transmissionSelectors = JSON.parse(
            localStorage.getItem('transmissionSelectors')
        )
        localStorage.removeItem('transmissionSelectors')
        if (transmissionSelectors) {
            setSelectors({
                ...transmissionSelectors.selectors,
            })
            setSelected({
                ...transmissionSelectors.selected,
            })
        }
    }, [fetchYear])

    const onSelectChange = (selector, e) => {
        const fieldName = e.target.name

        if (fieldName === 'make') {
            setSelected({
                ...selected,
                model: '',
                engine: '',
                [fieldName]: e.target.value,
            })
        } else if (fieldName === 'year') {
            setSelected({
                make: '',
                model: '',
                engine: '',
                [fieldName]: e.target.value,
            })
        } else if (fieldName === 'model') {
            setSelected({
                ...selected,
                engine: '',
                [fieldName]: e.target.value,
            })
        } else {
            setSelected({
                ...selected,
                [fieldName]: e.target.value,
            })
        }
    }

    const iniframe = ((new URLSearchParams(useLocation().search)).get("iniframe") === "true")
    const frame_target = (iniframe ? '_parent' : '_blank');

    return (
        <Container>
            <Text className="font-bold text-l font-heading">
                Search by Speed:
            </Text>
            <Stack
                direction={{base: 'column', lg: 'row'}}
                spacing={1}
                className="p-2 mb-2"
                sx={{
                    label: {color: 'white', fontStyle: 'italic', mb: 1},
                    '.chakra-stack': {
                        width: {base: '50%', lg: '9%'},
                        alignItems: 'flex-start',
                    },
                }}
            >
                <VStack>
                    <ContentButton
                        button={{
                            title: '2-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/2-speed'
                        }}
                        rel="noreferrer"
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '3-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/3-speed'
                        }}
                        rel="noreferrer"
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '4-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/4-speed'
                        }}
                        rel="noreferrer"
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '5-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/5-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '6-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/6-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '7-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/7-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '8-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/8-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '9-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/9-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
                <VStack>
                    <ContentButton
                        button={{
                            title: '10-Speed',
                            target: frame_target,
                            size: 'small',
                            url: 'https://atsgbookstore.com/10-speed'
                        }}
                        variant="darkBlue"
                        w="95%"
                    />
                </VStack>
            </Stack>

            <Text className="font-bold text-l font-heading">
                {iniframe ? 'Search by Vehicle' : 'Enter Vehicle Information:'}
            </Text>
            <form>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    spacing={5}
                    className="p-8 mb-5 bg-darkBlue"
                    sx={{
                        label: {color: 'white', fontStyle: 'italic', mb: 1},
                        '.chakra-stack': {
                            width: {base: '100%', lg: '50%'},
                            alignItems: 'flex-start',
                        },
                    }}
                >
                    <VStack>
                        <FormControl id="year">
                            <FormLabel>Year</FormLabel>
                            <Select
                                placeholder="Year"
                                name="year"
                                layerStyle="input"
                                onChange={(e) => onSelectChange('year', e)}
                                value={year}
                            >
                                {years?.map((year) => {
                                    return (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </VStack>
                    <VStack>
                        <FormControl id="make">
                            <FormLabel>Make</FormLabel>
                            <Select
                                placeholder={fetching ? 'loading' : 'Select Make'}
                                name="make"
                                layerStyle="input"
                                onChange={(e) => onSelectChange('make', e)}
                                value={make}
                            >
                                {makes?.map((make) => {
                                    return (
                                        <option key={make} value={make}>
                                            {make}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </VStack>
                    <VStack>
                        <FormControl id="model">
                            <FormLabel>Model</FormLabel>
                            <Select
                                placeholder={fetching ? 'loading' : 'Select Model'}
                                name="model"
                                layerStyle="input"
                                onChange={(e) => onSelectChange('model', e)}
                                value={model}
                            >
                                {models?.map((model) => {
                                    return (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </VStack>
                    <VStack>
                        <FormControl id="engine">
                            <FormLabel>Engine</FormLabel>
                            <Select
                                placeholder={fetching ? 'loading' : 'Select Engine Size'}
                                name="engine"
                                layerStyle="input"
                                onChange={(e) => onSelectChange('engine', e)}
                                value={engine}
                            >
                                {engines?.map((engine) => {
                                    return (
                                        <option key={engine} value={engine}>
                                            {engine}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </VStack>
                </Stack>
            </form>

            <ResultsTable
                headers={iniframe ? (<></>) : (
                    <>
                        <Th w={{base: '60%', md: '300px'}}>Transmission Results</Th>
                        <Th w={{base: '40%', md: '200px'}}>Bookstore</Th>
                    </>
                )}
            >
                {transmissions?.map((item, i) => {
                    return (
                        <Tr key={i} className="flex" sx={{'&:first-of-type': {pt: 5}}}>
                            <Td
                                w={{base: '60%', md: '300px'}}
                                className="text-sm uppercase"
                            >
                                {item}
                            </Td>
                            <Td w={{base: '40%', md: '200px'}}>
                                <a
                                    href={`https://atsgbookstore.com/search.php?search_query=${item}`}
                                    target={iniframe ? '_parent' : '_blank'}
                                    rel="noreferrer"
                                    className="text-sm font-normal underline text-lightBlue hover:text-darkBlue"
                                >
                                    Shop Bookstore
                                </a>
                            </Td>
                        </Tr>
                    )
                })}
            </ResultsTable>
            {
                (!transmissions || !transmissions.length) && (!iniframe || some_engine_selected) && (
                    <div className="mt-5">{noResults}</div>
                )
            }
        </Container>
    )
}

export default TransmissionPage
